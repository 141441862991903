<template>
  <div>
    <h2>Enti al Target</h2>
    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
    <v-row class="mt-5">
      <v-col cols="6">
        <enti-al-target :statistica="statisticaEnti" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import EntiAlTarget from '../elementi/entiAlTarget'
  import selfapi from '@/services/selfapi.js'

  export default {
    components: {
      EntiAlTarget
    },
    data () {
      return {
        totaleEnti: 0,
        loading: false,
        filtri: null,
        statisticaEnti: {},
        tipologie: ['Scuole', 'Comuni'],
        periodi: []
      }
    },
    async mounted () {
      this.periodi = []
      for (let tipo of this.tipologie) {
        for (let p = 0; p < 3; p++) {
          let filtro = {
            tipologia: tipo,
            end: null
          }
          switch(p) {
            case 0:
              filtro.start = moment().utcOffset(0, true).add(-7, 'days').startOf('day').format('YYYY-MM-DD')
              break
            case 1:
              filtro.start = moment().utcOffset(0, true).add(-30, 'days').startOf('day').format('YYYY-MM-DD')
              break
            default:
              filtro.start = null
          }        
          this.periodi.push(filtro)
        }
      }
      await this.getDataFromApi()
    },
    methods: {
      async onRicerca(filtri) {
        this.filtri = filtri
        await this.getDataFromApi()
      },
      async getDataFromApi () {
        try {
          this.loading = true
          let statisticaEnti = await selfapi.getStatisticaEnti()
          statisticaEnti = statisticaEnti.filter(x => ['Scuole', 'Comuni'].includes(x.tipologia))
          this.statisticaEnti = {}
          for (let s of statisticaEnti) {
            this.statisticaEnti.aderito = (this.statisticaEnti.aderito || 0) + s.aderito
            this.statisticaEnti.asseveratiPositivi = (this.statisticaEnti.asseveratiPositivi || 0) + s.asseveratiPositivi
            this.statisticaEnti.contrattualizzato = this.statisticaEnti.contrattualizzato || {}
            this.statisticaEnti.contrattualizzato.true = (this.statisticaEnti.contrattualizzato.true || 0) + s.contrattualizzato.true
            this.statisticaEnti.contrattualizzato.false = (this.statisticaEnti.contrattualizzato.false || 0) + s.contrattualizzato.false
            this.statisticaEnti.inAsseverazione = (this.statisticaEnti.inAsseverazione || 0) + s.inAsseverazione
            this.statisticaEnti.inRilavorazione = (this.statisticaEnti.inRilavorazione || 0) + s.inRilavorazione
          }
        } catch(err) {
        } finally {
          this.loading = false
        }
      }
    }    
  }
</script>